import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../store/useStore";
import { useLoading } from "../../context/loading";
import repository from "../../core/api";
import * as images from "./../../assets/images";
import "./questionnaire.scss";

const DesktopQuestionnairePage: React.FC = () => {
  const { form, schoolInfo: school, answers, addAnswer, updateQuestionnaires, questionnaires, setCurrentForm, clearAnswer, reset } = useStore();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: string]: string;
  }>({});
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const questionnaire = form.questionnaire;

  const handleAnswer = (questionId: string, answerId: string) => {
    addAnswer(questionId, answerId);
    setSelectedAnswers({ ...selectedAnswers, [questionId]: answerId });
  };

  const handleNext = async () => {
    try {
      if (currentQuestionIndex < (questionnaire.length || 0) - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setLoading(true);
        const formId = form.id;
        const answer_payload = Object.keys(answers).map((key: string) => ({ question_id: key, answer_id: answers[key] }))
        await repository.put(`/assessment-answer/${formId}`, answer_payload);
        const newBook: any[] = [...questionnaires].slice(1);
        updateQuestionnaires(newBook);
        setLoading(false);
        if (newBook.length > 0) {
          navigate("/onbording", { replace: true });
          setTimeout(() => {
            clearAnswer();
            setCurrentForm(newBook[0] ?? null);
          }, 200);
        } else {
          navigate("/completion", { replace: true });
          setTimeout(() => {
            reset();
          }, 200);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const currentQuestion = questionnaire[currentQuestionIndex];
  const currentStep = currentQuestionIndex + 1;

  return (
    <div className="questionnaire-desktop min-h-screen">
      <img className="bg-image" src={images.VectorBGF} alt="background" />
      <div className="logos">
        <img src={images.logoTac} alt="logo" />
        <img style={{ height: 65 }} src={school.white_logo_url} alt="school-logo" />
      </div>
      <div className="box">
        <div className="relative w-full h-1 bg-gray-200 rounded-full" style={{ marginBottom: 24 }}>
          <div
            className="absolute top-0 left-0 h-full bg-blue-500 rounded-full"
            style={{
              width:
                (
                  (currentQuestionIndex * 100) /
                  questionnaire.length
                ).toString() + "%",
            }}
          ></div>
        </div>
        <div className="box-header">
          <div className="question-step">
            <span className="current">
              {currentStep > 9 ? currentStep : `0${currentStep}`}
            </span>
            <span> / {questionnaire.length}</span>
          </div>
          <div className="header">
            <div className="title">{form.name}</div>
            <div className="description">{currentQuestion.text}</div>
          </div>
        </div>
        <div className="box-content">
          <div className="left">
            <h1 className="title">{form.name}</h1>
            <h2 className="title">{currentQuestion.subtext}</h2>
          </div>
          <div className="right">
            <div
              style={{
                flex: "1",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {currentQuestion.options.map((option: any) => (
                <label
                  key={option.id}
                  className={`flex items-center justify-between option cursor-pointer ${selectedAnswers[currentQuestion.id] === option.id
                    ? "selected-option"
                    : ""
                    }`}
                >
                  <span>{option.text}</span>
                  <input
                    key={option.text}
                    type="radio"
                    name={currentQuestion.id}
                    value={option.id}
                    checked={
                      selectedAnswers[currentQuestion.id] === option.id
                    }
                    onClick={() =>
                      handleAnswer(currentQuestion.id, option.id)
                    }
                    onChange={() => { }}
                    className="form-radio"
                  />
                </label>
              ))}
            </div>
            <div className="button-container">
              {currentQuestionIndex > 0 && (
                <button className="previous-button" onClick={handlePrev}>
                  Previous
                </button>
              )}
              <button
                className="button"
                disabled={!Boolean(selectedAnswers[currentQuestion.id])}
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopQuestionnairePage;
